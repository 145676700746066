import React, { useEffect, useRef } from "react";
import closeIcon from "assets/close.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "assets/campusrunzLogo.svg";
import campusrunz from "assets/campusrunz.svg";
import { cx } from "core/helpers/helpers";
import ALink from "./General/ALink";
import { APP_LINKS, EXTERNAL_LINKS } from "core/const/systemConst";

export default function SideNav({
  onClose = () => {},
  isOpen = false,
}: {
  onClose?: () => void | undefined;
  isOpen: boolean;
}) {
  const sideNavRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        sideNavRef.current &&
        !sideNavRef.current.contains(event.target as Node) &&
        isOpen
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <>
      <div
        ref={sideNavRef}
        className={cx(
          "fixed w-full overflow-hidden rounded-b-[8px] border border-white transition-all duration-500 ease-in-out",
          isOpen ? "-left-0" : "-left-[100vw]",
        )}
        style={{
          zIndex: 1000,
        }}
      >
        <div className={`bg-white backdrop-filter`}>
          <nav className="mx-auto flex w-[90%] items-center justify-between py-[16px]">
            <div className="flex w-auto items-center gap-[50px] lg:w-1/2">
              <Link
                to="/"
                className="flex items-center gap-2"
                onClick={() => onClose()}
              >
                <img
                  src={logo}
                  alt=""
                  className="inline-block h-[40px] w-[40px]"
                  loading="lazy"
                />
                <img
                  src={campusrunz}
                  alt="Campusrunz"
                  className="inline-block h-[20px] w-auto"
                  loading="lazy"
                />
              </Link>
            </div>

            <div className="flex items-center justify-between gap-10">
              <button
                className="text-primary text-4xl"
                onClick={() => onClose()}
              >
                <img
                  src={closeIcon}
                  alt=""
                  loading="lazy"
                  className="h-[40px] w-[40px]"
                />
              </button>
            </div>
          </nav>
        </div>

        <div className="bg-[#56038E]">
          <nav className="mx-auto flex w-[90%] flex-col pt-5">
            {APP_LINKS.map((link) => (
              <NavLink
                key={link.name}
                to={link.path}
                onClick={(e) => {
                  if (link?.disabled) {
                    e?.preventDefault();
                  } else if (link?.path === "/contact") {
                    e?.preventDefault();
                    const contactDiv = document.getElementById("contact");
                    if (contactDiv) {
                      contactDiv.scrollIntoView({ behavior: "smooth" });
                    }
                  } else if (link?.path === "/faqs") {
                    e?.preventDefault();
                    navigate(`/#faqs`);
                  }
                  onClose();
                }}
                className={({ isActive }) =>
                  isActive
                    ? "mb-3 text-white underline hover:underline"
                    : "mb-3 text-white hover:underline"
                }
              >
                <p>{link.name}</p>
              </NavLink>
            ))}

            <a
              href={EXTERNAL_LINKS.SignUp}
              className="mb-3 text-white hover:underline"
            >
              Sign up
            </a>

            <a
              href={EXTERNAL_LINKS.Login}
              className="mb-3 text-white hover:underline"
            >
              Login
            </a>
          </nav>

          <div className="mx-auto flex w-[90%] items-center justify-center gap-3 py-[40px]">
            <ALink
              href={EXTERNAL_LINKS.DownloadApp}
              className="w-full !border !border-purple !bg-purple !font-[600] !text-white sm:w-2/3 md:w-1/2"
            >
              Download app
            </ALink>
          </div>
        </div>
      </div>
    </>
  );
}
