import React from "react";
import Button from "./General/Button";
import { Link } from "react-router-dom";
import logo from "assets/campusrunzLogo.svg";
import campusrunz from "assets/campusrunz.svg";
import mail from "assets/mail.svg";
import linkedin from "assets/linkedin.svg";
import twitter from "assets/twitter.svg";
import youtube from "assets/youtube.svg";
import discord from "assets/discord.svg";
import slack from "assets/slack.svg";
import instagram from "assets/instagram.svg";
import facebook from "assets/facebook.svg";

export default function Footer() {
  const socials = [
    {
      name: "Email",
      logo: mail,
      url: "mailto:hello@campusrunz.com.ng",
    },
    {
      name: "Linkedin",
      logo: linkedin,
      url: "https://www.linkedin.com/company/mycampusrunz/",
    },
    {
      name: "Twitter",
      logo: twitter,
      url: "https://twitter.com/mycampusrunz",
    },
    {
      name: "Youtube",
      logo: youtube,
      url: "",
    },
    {
      name: "Discord",
      logo: discord,
      url: "",
    },
    {
      name: "Slack",
      logo: slack,
      url: "",
    },
    {
      name: "Facebook",
      logo: facebook,
      url: "https://www.facebook.com/mycampusrunz/",
    },
    {
      name: "Instagram",
      logo: instagram,
      url: "https://www.instagram.com/mycampusrunz",
    },
  ];
  return (
    <footer className="bg-white">
      <div className="h-[15px] w-1/12 bg-[#F956FF]"></div>
      <div className="mx-auto w-[90%] items-center pt-[75px]">
        <section className="mb-20">
          <header className="mb-10 flex w-full flex-col items-center gap-2 md:flex-row">
            <h5 className="text-[24px] font-bold text-[#56038E] md:p-3">
              Stay up to date
            </h5>
            <p className="md:border-l md:border-l-[#ACACAC] md:p-5">
              Subscribe to our newsletter and never miss an update. <br /> Get
              the latest news and offers straight to your inbox. <br />
              <span className="text-[#56038E] text-sm font-semibold">*Coming soon</span>
            </p>
          </header>

          <form className="flex w-full flex-col gap-3 sm:flex-row sm:gap-0">
            <input
              type="text"
              disabled
              className="h-12 w-full cursor-not-allowed rounded-l-[4px] border border-[#C4C4C4] p-3 outline-none sm:w-10/12"
              placeholder="Your email address"
            />
            <Button
              disabled
              className="w-full bg-purple text-white sm:!w-2/12 sm:!rounded-l-none"
            >
              Join
            </Button>
          </form>
        </section>

        <section className="mb-20 grid grid-cols-12 gap-8">
          <div className="col-span-12 lg:col-span-3">
            <Link to="/" className="flex items-center gap-2">
              <img
                src={logo}
                alt=""
                className="inline-block h-[40px] w-[40px]"
                loading="lazy"
              />
              <img
                src={campusrunz}
                alt="Campusrunz"
                className="inline-block h-[20px] w-auto"
                loading="lazy"
              />
            </Link>
          </div>

          <div className="col-span-12 flex flex-col gap-5 sm:col-span-4 lg:col-span-3">
            <p className="font-bold">Our Services</p>
            <p>Accommodation</p>
            <p>Marketplace</p>
            <p>Laundry</p>
            <p>Maintenance</p>
            <p>Foodstuff</p>
          </div>

          <div className="col-span-12 flex flex-col gap-5 sm:col-span-4 lg:col-span-3">
            <p className="font-bold">Contact Info</p>
            <a href="http://2347067035892" className="hover:underline">
              Whatsapp
            </a>
            <a href="tel:+2349065406276" className="hover:underline">
              +234 906 540 6276
            </a>
            <a
              href="mailto:hello@campusrunz.com.ng"
              className="hover:underline"
            >
              hello@campusrunz.com.ng
            </a>
            <Link to="/privacy" className="hover:underline">
              Our terms and conditions
            </Link>
          </div>

          <div className="col-span-12 flex flex-col gap-5 sm:col-span-4 lg:col-span-3">
            <p className="font-bold">Our Universities</p>
            <p>Primer Docs</p>
            <p>API Reference</p>
            <p>Payments method guide</p>
            <p>Service status</p>
            <p>Community</p>
          </div>

          <div className="col-span-0 lg:col-span-3"></div>

          <div
            id="contact"
            className="col-span-12 flex items-center gap-5 sm:col-span-10 lg:col-span-3 lg:mt-10"
          >
            {socials
              ?.filter((s) => s?.url?.length > 5)
              ?.map((soc) => (
                <a
                  key={soc?.name}
                  href={soc?.url}
                  className="min-h-[28px] min-w-[28px]"
                >
                  <img
                    src={soc?.logo}
                    alt={soc?.name}
                    className="h-[28px] w-[28px]"
                  />
                </a>
              ))}
          </div>
        </section>

        <section className="border-t border-t-[#EEEEEE] p-3 text-[#959595] md:p-10">
          <p>
            © Campusrunz {new Date().getFullYear()} - A product of Sourceone
            Consulting and Technologies Limited
          </p>
        </section>
      </div>
    </footer>
  );
}
