import user1 from "assets/user1.png";
import user2 from "assets/user2.png";
import user3 from "assets/user3.png";
import user4 from "assets/user4.png";

export const EXTERNAL_LINKS = {
  Login: "https://app.campusrunz.com/login",
  DownloadApp: "https://www.onelink.to/svu2ne",
  MerchantSignup: "https://www.portal.campusrunz.com/auth/signup",
  SignUp: "https://app.campusrunz.com/registration",
};

export const FAQS = [
  {
    question: "Can I request my university to be added to the network?",
    answer:
      "Yes, If your institution is not currently supported, you can contact us through our support channels, and we will consider adding it to our network.",
  },
  {
    question: "Are the services subscription-based?",
    answer:
      "No, our services are pay-as-you-go. You only pay for what you buy.",
  },
  {
    question: "Is there a cost associated with using the app's services?",
    answer:
      "Yes, our services have affordable fees, tailored to meet students' budgets. Users also pay convenience and delivery fees, which vary based on the location and the product/service paid for.",
  },
  {
    question: "Are there any special offers or discounts available?",
    answer:
      "Yes, we offer loyalty points for frequently using our services. Discounts are also provided on a school and service basis and will be communicated when active.",
  },
  {
    question: "Can I use the app if I am not a student?",
    answer:
      "Yes, non-students can use the app, but some features may be tailored specifically for students.",
  },
  {
    question: "How can I provide feedback about the app?",
    answer:
      "Use the 'Contact us' page or reach out to us via email, phone or social media.",
  },
  {
    question: "Which institutions are currently supported by the app?",
    answer:
      "You can check on our web page to see list of universities supported by our app. If your university isn't listed, you can request to add it through our support channels",
  },
  {
    question: "What payment methods are available?",
    answer:
      "You can pay using the in-app wallet or cards and transfers or other supported payment method.",
  },
  {
    question: "I forgot my password, what should I do?",
    answer: "Click 'forgot password' on the login page and reset it",
  },
  {
    question: "How do I contact Campusrunz if I have an issue?",
    answer:
      "Use the 'Contact Us' page or reach out via email, phone or social media platform or tap the 'assistance icon' in the top right corner of the log in page on the app to reach out for assistance",
  },
];

export const APP_LINKS = [
  {
    name: "Home",
    path: "/",
    disabled: false,
  },
  {
    name: "About us",
    path: "/about",
    disabled: false,
  },
  {
    name: "Contact us",
    path: "/contact",
    disabled: false,
  },
  {
    name: "FAQs",
    path: "/faqs",
    disabled: false,
  },
];

export const TESTIMONIALS = [
  {
    name: "J.Thompson",
    image: user2,
    school: "Fast food vendor",
    comment:
      "CampusRunz reputation is second to none, they hold the funds and confirm both parties satisfaction before the settlement and the settlement is done timely. This has helped my business a whole lot. Thank you CampusRunz.",
  },
  {
    name: "M. Awwal",
    school: "Laundry vendor",
    image: user1,
    comment:
      "Campusrunz have reduced the timeframe for delivering my merchandise to my customers, customers can now be rest assured that their goods will be delivered on time. This has increased the rate of my turnovers.",
  },
  {
    name: "Ayodeji Peter",
    image: user4,
    school: "Student",
    comment:
      "This is the best app to do runs for students on and off OAU campus. Cheers to the developers",
  },
  {
    name: "Ogugua Chisom",
    image: user3,
    school: "Student",
    comment: "This app gives me 100% convenience.",
  },
];
