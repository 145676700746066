import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { ScrollToTop } from "core/helpers/ScrollToTop";
import { lazy, Suspense } from "react";
import Navbar from "core/components/Navbar";
import Loader from "core/components/Loader";
import Footer from "core/components/Footer";

const Home = lazy(() => import("./pages/Home"));
const Privacy = lazy(() => import("./pages/Privacy"));
const About = lazy(() => import("./pages/About"));
const NotFound = lazy(() => import("./pages/NotFound"));

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className="relative overflow-x-hidden">
        <Navbar />
        <Suspense fallback={<Loader />}>
          <div
            style={{
              zIndex: 50,
              minHeight: "80vh",
            }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </div>
        </Suspense>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
