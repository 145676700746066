import React from "react";
import logo from "assets/campusrunzLogo.svg";

export default function Loader({ message = "" }: { message?: string }) {
  return (
    <div
      className="no-scrollbar fixed inset-0 flex h-screen w-screen items-center justify-center overflow-auto bg-[#6C18A4] bg-opacity-[.5]"
      style={{ minHeight: "calc(100vh - 72px)", zIndex: 3000 }}
    >
      <div className="flex flex-col items-center justify-center gap-1">
        <img
          src={logo}
          loading="lazy"
          alt="Campusrunz logo"
          className="h-12 w-auto animate-bounce"
        />
        <p className="text-lg text-black">{message || "Loading..."}</p>
      </div>
    </div>
  );
}
