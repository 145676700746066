import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "assets/campusrunzLogo.svg";
import campusrunz from "assets/campusrunz.svg";
import SideNav from "./SideNav";
import hamburger from "assets/hamburger.svg";
import { APP_LINKS, EXTERNAL_LINKS } from "core/const/systemConst";
import ALink from "./General/ALink";

const Navbar = () => {
  const [showSidenav, setSidenav] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div
        className="fixed left-0 right-0 top-0 bg-white"
        style={{
          zIndex: 1000,
        }}
      >
        <nav className="mx-auto flex w-[90%] items-center justify-between py-3">
          <Link to="/" className="flex items-center gap-2">
            <img
              src={logo}
              alt=""
              className="inline-block h-[40px] w-[40px]"
              loading="lazy"
            />
            <img
              src={campusrunz}
              alt="Campusrunz"
              className="inline-block h-[20px] w-auto"
              loading="lazy"
            />
          </Link>

          <div className="flex items-center justify-between gap-5">
            {APP_LINKS?.map((link) => (
              <NavLink
                key={link?.name}
                to={link?.path}
                onClick={(e) => {
                  if (link?.disabled) {
                    e?.preventDefault();
                  } else if (link?.path === "/contact") {
                    e?.preventDefault();
                    const contactDiv = document.getElementById("contact");
                    if (contactDiv) {
                      contactDiv.scrollIntoView({ behavior: "smooth" });
                    }
                  } else if (link?.path === "/faqs") {
                    e?.preventDefault();
                    navigate(`/#faqs`);
                  }
                }}
                className={({ isActive }) =>
                  isActive
                    ? "hidden text-purple underline hover:text-purple hover:underline lg:block"
                    : "hidden text-black hover:text-purple hover:underline lg:block"
                }
              >
                {link?.name}
              </NavLink>
            ))}

            <a
              href={EXTERNAL_LINKS.SignUp}
              className="hidden text-black hover:text-purple hover:underline lg:block"
            >
              Sign up
            </a>

            <ALink
              href={EXTERNAL_LINKS.Login}
              className="hidden !border !border-purple !bg-purple-400 !font-[600] !text-purple md:block"
            >
              Login
            </ALink>

            <ALink
              href={EXTERNAL_LINKS.DownloadApp}
              className="hidden !border !border-purple !bg-purple !font-[600] !text-white sm:block"
            >
              Download app
            </ALink>

            <button
              className="block bg-white lg:hidden"
              onClick={() => setSidenav(!showSidenav)}
            >
              <img
                src={hamburger}
                alt=""
                loading="lazy"
                className="h-[40px] w-[40px]"
              />
            </button>
          </div>
        </nav>
      </div>
      <SideNav isOpen={showSidenav} onClose={() => setSidenav(false)} />
    </>
  );
};

export default Navbar;
